.exportData{
    width: 100%;
    height: 100%;
    background: #F3F6FC;
    .container{
        width: 848px;
        margin: 0 auto;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 6px 12px 0px rgba(242, 242, 242, 0.5);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 24px;
        .tabs{
            padding-bottom: 24px;
            display: flex;
            box-shadow: 0px 1px 0px 0px #EBEDF2;
            .item{
                padding: 9px 10px;
                border-radius: 4px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #989AB3;
                cursor: pointer;
                margin-right: 20px;
                &.active{
                    border-radius: 4px;
                    background: #3599FE;
                    color: #FFFFFF;
                    font-weight: bold;
                }
            }
        }
        .export-main{
            padding-top: 24px;
            display: flex;
            align-items: center;
            flex-direction: column;
            .ant-form{
                width: 100%;
            }
            &-item{
                &-l{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #464668;
                    line-height: 14px;
                }
            }
            .activeBtn{
                width: 124px;
                height: 44px;
                margin: 57px auto;
            }
        }
    }
}